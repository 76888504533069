// Colors
// $bg-color: hsl(256,33,70);
$bg-color: hsl(255,100,100);
$dot-color: hsl(256,33,10);

// Dimensions
$dot-size: 1px;
$dot-space: 8px;

// .dot-pattern {
.spacer {
	background:
		linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
		linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
		$dot-color;
	background-size: $dot-space $dot-space;
}

.lamination {
	background: hsl(0,0.0,50.2),
	// background: 'black',
	// background: repeating-linear-gradient(-55deg, #dfdfdf, #fafafa 15px, #ffffff 15px, #ffffff 30px),
}

.gl_pane {
	background: repeating-linear-gradient(-55deg, #dfdfdf, #fafafa 15px, #ffffff 15px, #ffffff 30px),
}