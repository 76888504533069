/* #external-events {
  position: absolute;
  z-index: 2;

  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #eee;
  overflow-y: scroll;

  width: 14%;
  height: 60%;
}

#external-events .fc-event {
  margin: 1em 0;
  cursor: move;
  background-color: #3788D8;
  color: white;
} */

.fc .fc-daygrid-event {
  height: 20px;
  font-size: 0.7em;
}

.fc .fc-establishButton-button {
  background-color: #1976D2;
  border: 1px #1976D2;
}

.fc .fc-establishButton-button:hover {
  /* 색상값을 single quotation으로 감싸면 안됨 */
  background-color: #1565C0;
}

/* 종일 영역을 넓힘 (현재 시간영역 사용안함) */
.fc .fc-timegrid-axis-cushion {
  height: 500px;
}

.fc-media-screen .fc-timegrid-cols {
  height: 0%;
}

