table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
}

ul {
  margin: 0;
}

ul.dashed {
  list-style-type: none;
}

ul.dashed > li {
  text-indent: -25px;
}

ul.dashed > li:before {
  content: "- ";
  text-indent: -25px;
}